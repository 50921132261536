import { Loadable } from "@epsilon-org/react";
import { render } from "preact";

import Loader from "@/components/Loader";

import styles from "./index.module.scss";

const pageNode = document.getElementById("hedges-product-page");

if (pageNode) {
	const ProductPage = Loadable({
		loading: <Loader className={styles.loader} />,
		loader: () => import("./index"),
	});

	render(<ProductPage />, pageNode);
}
