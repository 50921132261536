import { Loadable } from "@epsilon-org/react";
import { render } from "preact";

import Loader from "@/components/Loader";

// Countdown.
const countdownNode = document.getElementById(
	"hh-discount-promotion-countdown",
);

if (countdownNode) {
	const Countdown = Loadable({
		loading: <Loader />,
		loader: () => import("./countdown"),
	});

	render(<Countdown />, countdownNode);
}
