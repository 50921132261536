import { Loadable } from "@epsilon-org/react";
import { render } from "preact";

import Loader from "@/components/Loader";

const pageNode = document.getElementById("hh-hedges-filter");

if (pageNode) {
	const HedgesFilter = Loadable({
		loading: <Loader />,
		loader: () => import("./index"),
	});

	render(<HedgesFilter />, pageNode);
}
