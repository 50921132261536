import classNames from "classnames";
import type { ComponentProps } from "preact";

import styles from "./index.module.scss";

type LoaderProps = ComponentProps<"div">;

export default function Loader({ className }: LoaderProps) {
	return (
		<div className={classNames(styles.loader, className)}>
			<div className={classNames(styles.spinner, "loadingSpinner")} />
		</div>
	);
}
